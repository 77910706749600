.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html * {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 25px;
}

/* Start Page */
.start-page {
  height: 100vh;
  width: 100vw;
  background-color: rgb(161, 157, 152);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button */
.page-button {
  display: block;
  height: 100px;
  width: 150px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 25px;
  border-radius: 20px;
  border: 3px solid rgb(80, 79, 78);
  background-color: rgb(107, 109, 109);
  appearance: none;
  color: antiquewhite;
}
.page-button:active {
  font-size: 24px;
  width: 145px;
  height: 95px;
  
}

/* End Page */
.end-page {
  height: 100vh;
  width: 100vw;
  background-color: rgb(161, 157, 152);
  display: flex;
  justify-content: center;
  align-items: center;
}
.end-page-container {
  width: 15vw;
  height: 300px;  
}
.end-page-container > div {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameWon {
  background-color: rgb(106, 228, 106);
}
.gameLose {
  background-color: rgb(230, 111, 111);
}

/* Game page */
.game-page {
  height: 100vh;
  width: 100vw;
  background-color: rgb(161, 157, 152);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2fr 6fr 6fr;
  grid-template-areas: 
    'n n'
    'w i'
    'k k';
}
/* Game Name */
.game-name {
  grid-area: n;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(107, 109, 109);
  border: 3px solid rgb(80, 79, 78);
  border-radius: 5px;
  margin: 1%;
  color:antiquewhite;
}
/* Displayed word block */
.displayed-word-container {
  grid-area: w;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}
.displayed-word {
  display: flex;
  justify-content: center;
  padding: 10% 0 10% 0;
}
.displayed-letter {
  display: inline-block;
  height: 30px;
  width: 20px;
  margin: 0 1% 0 1%;
}
.hint {
  display: flex;
  justify-content: center;
  font-weight: normal;
}
.hint > span {
  font-weight: normal;
  font-style: italic;
}

.hangman-img {
  grid-area: i;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 50%;
}

/* Keyboard */
.keyboard {
  grid-area: k;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  width: 100%;
  max-width: 100%;
}
.key {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}
.key > div {
  font-size: 4vh;
  font-weight: bold;
  margin: 5px;
  border: 3px solid rgb(80, 79, 78);
  background-color: rgb(107, 109, 109);
  color: antiquewhite;
  border-radius: 5px;
  height: 90%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notPressed > div:hover {
  font-size: 3vh;
  height: 80%;
  width: 80%;

}
.rightLetter > div {
  background-color: rgb(106, 228, 106);
}
.notRightLetter > div {
  background-color: rgb(230, 111, 111);
}